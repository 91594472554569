/* .research-container {
display: flex;
} */
.research-experience-container {
  margin: 4rem 0;
  /* position: relative; */
  /* max-width: 50%; */
}
.divided-container {
  display: flex;
  margin: 4rem 0;
  gap: 3rem;
  /* position: relative; */
  /* max-width: 50%; */
}

.research-interest-container h5 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 3rem;
}

.research-experience-container h5 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 3rem;
}

.research-interest-container h5 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 3rem;
}

.point-title {
  font-weight: bold;
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 400;
  background: rgba(103, 81, 185, 0.5);
  border-radius: 0.3rem;
  padding: 0.2rem 0.4rem;
  margin-top: 0.5rem;
}
.research-duration {
  display: inline-block;
  font-size: 0.7rem;
  font-weight: 400;
  background: rgba(103, 81, 185, 0.5);
  border-radius: 0.3rem;
  padding: 0.4rem 0.6rem;
  margin-bottom: 0.5rem;
}

.research-experience-card {
  background: #130f2a;
  border-radius: 0.65rem;
  border: 1.5px solid #6751b9;
  padding: 1.5rem;
  margin: 0 1rem;
}

.research-experience-card h6 {
  font-size: 1.05rem;
  font-weight: 500;
  margin-bottom: 0.7rem;
}

.research-experience-card ul li {
  list-style: none;
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
  position: relative;
}

.research-experience-card ul li::after {
  content: " ";
  width: 0.5rem;
  height: 0.5rem;
  background-color: #6751b9;
  border-radius: 0.5rem;
  position: absolute;
  left: -1.3rem;
  top: 0.35rem;
}

.education-card {
  min-height: 16rem;
}
.education-duration {
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 400;
  background: rgba(103, 81, 185, 0.5);
  border-radius: 0.3rem;
  padding: 0.4rem 0.6rem;
  /* margin-bottom: 0.5rem; */
}

@media (max-width: 768px) {
  .research-experience-card {
    margin: 0;
  }

  .research-experience-card h6 {
    font-size: 0.95rem;
  }

  .research-experience-card ul li {
    font-size: 0.75rem;
  }
}
@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
  }

  .contact-container h5 {
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
  }
}