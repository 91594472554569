.contact-container {
  margin: 4rem 0;
  position: relative;
}

.contact-container h5 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 3rem;
}

.contact-content {
  display: flex;
  justify-content: space-between;
  /* gap: 1rem; */
}

@media (max-width: 960px) {
  .contact-content {
    flex-direction: column;
  }

  .contact-container h5 {
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
  }
}
.contact-details-card {
  min-width: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #130f2a;
  border-radius: 0.65rem;
  border: 1.5px solid #6751b9;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.contact-details-card .icon {
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background: #3d3072;
  margin-bottom: 0.8rem;
}

.icon img {
  width: 1.8rem;
  height: auto;
  object-fit: contain;
}

.contact-details-card p {
  font-size: 0.9rem;
  font-weight: 400;
  text-align: center;
}

@media (max-width: 400px) {
  .contact-details-card p {
    font-size: 0.8rem;
  }
}
